/* 
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-header {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  margin-right: 8px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 20px;
}

.navbar-links li a {
  text-decoration: none;
  color: #333;
  padding: 8px 12px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease;
}

.navbar-links li a.active {
  background-color: #1a73e8;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
}


.navbar-links li a::after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background-color: #1a73e8;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.3s ease;
}

.navbar-links li a:hover::after,
.navbar-links li a.active::after {
  width: 100%;
}

.login-button {
  background-color: #ff5252;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.login-button:hover {
  background-color: #ff6666;
}

@media (max-width: 768px) {
  .navbar-links {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      width: 250px;
      background-color: #f8f8f8;
      flex-direction: column;
      gap: 20px;
      padding-top: 60px;
      transform: translateX(100%);
      transition: transform 0.3s ease;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .sidebar-open {
      transform: translateX(0);
  }

  .menu-toggle {
      display: inline-block;
      margin-left: auto;
  }

  .navbar-links li a {
      padding: 15px 20px;
      font-size: 1rem;
  }

  .login-button {
      display: none;
  }
}

@media (max-width: 1024px) {
  .navbar {
      flex-direction: column;
      align-items: flex-start;
  }

  .navbar-logo {
      margin-bottom: 10px;
  }

  .navbar-links {
      justify-content: space-around;
      gap: 10px;
      width: 100%;
      padding: 0%;
  }


  .navbar-links li a {
      padding: 10px 0;
      width: 100%;
      text-align: left;
  }
} */



/* General Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.navbar-header {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 50px;
  margin-right: 8px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 10px;
  align-items: baseline;
}



.navbar-links li a {
  text-decoration: none;
  color: #333;
  padding: 8px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease;
}



.login-button {
  background-color: #ff5252;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.login-button:hover {
  background-color: #ff6666;
}


.shifted {
  margin-left: 250px; 
  transition: margin-left 0.3s ease;
}

@media (max-width: 1023px) {
  .navbar-links {
    overflow-y: auto; /* Enable scrolling for sidebar content */
    padding-top: 60px; /* Add space for the header */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
  }
  
  /* When sidebar is open */
  body.sidebar-open {
    overflow: hidden; /* This will only be applied when sidebar is open */
  }

  .navbar {
    justify-content: center;
  }

  .menu-toggle {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar-logo {
    display: block;
    margin: 0 auto;
  }
  
  .navbar-links {
    gap: 0px;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0; 
    right: auto; 
    transform: translateX(-100%); 
    height: 100vh;
    background-color: #f8f8f8;
    flex-direction: column;
    transition: all 0.25s ease-in-out;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 0%;
    align-items: normal;
  }


  .sidebar-open {
    transform: translateX(0);
  }

  .menu-toggle {
    display: inline-block;
    margin-right: auto;
    margin-left: 0;
  }

  .navbar-links li{
    text-align: left; 
    width: auto;
    height: auto;
    padding: 10px;
    border-bottom: 1px solid black;
  }

  .navbar-links li a {
    padding: 10px 5px;
    width: 100%;
    text-align: left;
    font-weight: 400;
  }

}

@media (min-width: 1024px) and  (max-width: 1439px) {
  .navbar {
    flex-direction: column;
}

.navigations{
  width: 100%;
}

.navbar-logo {
    margin-bottom: 10px;
}

.navbar-links {
    justify-content: space-around;
    gap: 0px;
    width: 100%;
    padding: 0%;
}


.navbar-links li a {
    padding: 10px;
    width: 100%;
    text-align: left;
    font-size: 13px;
    /* margin-bottom: 5px; */
    
}

.navbar-links li a.active {
  background-color: #1a73e8;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
}

.navbar-links li a::after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background-color: #1a73e8;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.3s ease;
}

.navbar-links li a:hover::after,
.navbar-links li a.active::after {
  width: 100%;
}

}

@media (min-width: 1025px){
  .navbar-links li a.active {
    background-color: #1a73e8;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .navbar-links li a::after {
    content: "";
    display: block;
    height: 2px;
    width: 0;
    background-color: #1a73e8;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: width 0.3s ease;
  }
  
  .navbar-links li a:hover::after,
  .navbar-links li a.active::after {
    width: 100%;
  }
}


/* Navbar.css */

/* Overlay style for when sidebar is open */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.765);
  z-index: 900;
  pointer-events: all;
  overflow-y: hidden; /* Prevent overlay scrolling */
}

/* Ensure the sidebar content shifts when the sidebar is open */
.sidebar-open ~ .overlay {
  display: block;
}

/* Prevent scrolling on body when sidebar is open */
body.sidebar-open {
  overflow: hidden; /* Disable background scrolling */
}


.dropdown {
  position: relative;
}

.dropdown-menu {
  width: max-content;
  padding: 10px;
  display: none;
  position: absolute;
  top: 30px;
  left: 0;
  list-style-type: none;
  /* padding: 0; */
  margin: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 8px 16px;
  margin-bottom: 5px;
}

.dropdown-menu li a {
  text-decoration: none;
  color: black;
}

.dropdown-menu li a:hover {
  background-color: #ddd;
}


/* In your Navbar.css */
@media (max-width: 768px) {
  .navbar-links .dropdown-menu {
    position: static;
    display: none;
    background: transparent;
    box-shadow: none;
    padding-left: 20px;
    border: none;
    margin-top: 0;
  }

  .navbar-links .dropdown-menu.show {
    display: block;
  }

  .navbar-links .dropdown-menu li {
    margin: 10px 0;
  }

  .dropdown-active > a {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.dropdown-menu {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
}

.dropdown-menu.show {
  height: auto;  /* Allows the element to expand to fit content */
  opacity: 1;
}

/* This additional rule helps create a smooth close */
.dropdown-menu.show-closing {
  height: 0;     /* Triggers the height transition */
  opacity: 0;    /* Triggers the opacity transition */
}








