.contactpage-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: white;
}

.contactheading-image {
  width: 100%;
  height: 55vh;
  object-fit: fill;
  filter: brightness(0.65);
}

/* Form container */
.contact-form-section {
  padding: 50px;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.form-main {
  max-width: 500px;
  width: 50%;
}

.form-heading p {
  color: #000000;
  font-weight: 400;
}

.form-container {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #0056b3;
  border-radius: 10px;
  box-shadow: 2px 4px #45688e;
}

.form-image {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Form Inputs Styling */
form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  font-weight: 400;
}

.contact-page input,
select {
  width: 100%; /* Ensure all inputs and select boxes have the same width */
  padding: 10px;
  /* font-size: 16px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box; /* Ensures padding is included in the total width */
  font-family: 'Poppins', sans-serif;
}

.contact-page input:focus,
select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  outline: none;
}

.contact-form-section textarea{
  height: 100px;
  width: 98%;
  border: 1px solid #ccc;
}

/* Button Styling */
.form-container button {
  padding: 12px;
  background-color: #f6c300;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 30%;
  align-self: center;
  transition: background-color 0.3s ease;
}
.form-container button:hover {
  background-color: #0056b3;
}

.contact-image {
  width: 80%;
  height: auto;
}
.map-section {
  display: flex;
  background-color: #ffffff;
  padding: 50px;
}

.map-container {
  width: 100%;
}

.contact-required {
  color: red;
  margin-left: 1px;
  font-size: 0.9em;
  font-weight: 100;
}



.contact-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.contact-popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.contact-popup p {
  margin-bottom: 15px;
}

.contact-popup button {
  padding: 8px 16px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.contact-popup button:hover {
  background: #0056b3;
}


@media (max-width: 768px) {

  .contactheading-image {
    height: 52vh;
  }

  .contact-form-section{
    flex-direction: column-reverse;
  }
  .form-container {
    padding: 20px;
    max-width: 400px;
  }

  .form-container button {
    width: 40%;
  }
  .map-section {
    flex-direction: column;
  }
  .map-container {
    width: 100%;
  }
  .address-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 576px) {
  .form-main {
    width: 100%;
  }
  .form-image {
    margin-top: 20px;
    width: 100%;
  }
  .form-container {
    padding: 15px;
    max-width: 100%;
  }

  .form-container button {
    width: 100%;
    align-self: center; /* Center button on small screens */
  }
}

@media (max-width:480px) {
  .contactheading-image {
    height: 32vh;
  }
  .contact-textarea{
    width: 95%;
  }
}
