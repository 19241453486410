/* ExamHome styles */
.exam-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
}

.exam-image {
    width: 100%;
    height: 55vh;
    object-fit: fill;
    filter: brightness(0.65);
}

/* ExamCalendar styles */
.exam-calendar-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 2rem;
}

.exam-calendar-header {
    text-align: center;
    margin-bottom: 3rem;
}

.exam-calendar-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

/* .blue-text {
    color: #1A45AC;
}

.yellow-text {
    color: #F6C300;
    margin-left: 10px;
} */

.underline {
    width: 180px;
    height: 4px;
    background-color: #1A45AC;
    margin: 0.5rem auto;
}

.exam-calendar-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    padding: 1rem;
}

.calendar-item {
    background-color: #F6C300;
    color: black;
    border-radius: 12px;
    padding: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.calendar-item:hover {
    background-color: #1A45AC;
    color: white;
}

.calendar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
}

.download-icon {
    font-size: 1.5rem;
}

/* Desktop styles */
@media (min-width: 769px) {
    .calendar-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }
}

/* Mobile styles */
@media (max-width: 768px) {
    .exam-calendar-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .exam-image {
        height: 52vh;
    }
    /* .exam-container {
        height: 300px;
    } */

    .calendar-item {
        padding: 1.25rem;
    }

    .calendar-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(0, 71, 171, 0.3);
    }

    .exam-calendar-header h1 {
        font-size: 2rem;
    }
}

/* Small mobile styles */
@media (max-width: 480px) {

    .exam-image {
        height: 32vh;
    }
    /* .exam-container {
        height: 140px;
    } */

    .exam-calendar-container {
        padding: 0 1rem;
    }

    .exam-calendar-header h1 {
        font-size: 1.75rem;
    }

    .calendar-title {
        font-size: 1.1rem;
    }

    .exam-calendar-grid {
        grid-template-columns: 1fr;
    }
}