/* Styles for ChatBanner component */
.shyam-methodology-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
    /* padding: 2.5rem 1.5rem; */
    /* width: 100%; */
    /* height: 150px; */
}

.shyam-methodology-image {
    width: 100%;
    height: 55vh;
    object-fit: fill;
    filter: brightness(0.65);
}

.shyam-methodology-text-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    animation: slideIn 1s ease-out forwards;
}

.shyam-methodology-content {
    max-width: 800px;
}

.shyam-methodology-title {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-left: 10%;
    transition: transform 0.3s ease, color 0.3s ease;
}


.shyam-methodology-title:hover {
    transform: scale(1.05);
    color: #f0f0f0;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translate(-100%, -50%);
    }

    100% {
        opacity: 1;
        transform: translate(0, -50%);
    }
}

/* Styles for ParagraphComponent */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.styled-paragraph {
    padding: 32px;
    font-size: 18px;
    line-height: 1.5;
    margin: 0px 88px;
    text-align: center;
}

/* Styles for MissionVision component */
.mission-vision-container {
    padding: 40px;
    background-color: #ffffff;
    margin-top: 0px;
}

.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.mission,
.vision {
    display: flex;
    flex-direction: column;
}

.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.vertical-line {
    width: 4px;
    height: 30px;
    background-color: #F6C300;
    margin-right: 10px;
}

.missionvision {
    font-size: clamp(24px, 4vw, 32px);
    font-weight: bold;
    margin: 0;
    color: #1A45AC;
}

.mission-content,
.vision-content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.para {
    flex: 1;
    line-height: 1.7;
    font-size: clamp(16px, 2vw, 20px);
    margin: 0;
}

.mission-image,
.vision-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    aspect-ratio: 16 / 10;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Tablet-specific styles */
@media (min-width: 601px) and (max-width: 1024px) {
    .mission-vision-container {
        padding: 30px;
    }

    .content-wrapper {
        gap: 30px;
    }

    .mission-content,
    .vision-content {
        gap: 15px;
    }

    .mission-image,
    .vision-image {
        max-width: 300px;
        min-width: 250px;
    }

    .para {
        font-size: 16px;
    }
}

/* Mobile styles */
@media (max-width: 768px) {

    .shyam-methodology-image {
        height: 52vh;
    }

    .styled-paragraph {
        font-size: 16px;
        padding: 16px;
        margin: 0 16px;
    }

    .container {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .mission-vision-container {
        padding: 20px;
    }

    .content-wrapper {
        gap: 40px;
    }

    .mission-content,
    .vision-content {
        flex-direction: column;
        gap: 20px;
    }

    .mission-image,
    .vision-image {
        max-width: 100%;
        margin: 0;
    }

    .mission .mission-image {
        order: -1;
    }

    .vision-content {
        flex-direction: column;
    }

    .vision-text {
        order: -1;
    }
}

/* Small mobile styles */
@media (max-width: 480px) {
    /* .shyam-methodology-container {
        height: 32vh;
    } */

    .shyam-methodology-image {
        height: 32vh;
    }

    .shyam-methodology-title {
        font-size: 1.5rem;
    }

    .mission-vision-container {
        padding: 15px;
    }

    .content-wrapper {
        gap: 30px;
    }

    .section-header {
        margin-bottom: 15px;
    }

    .vertical-line {
        height: 24px;
    }
}