/* ResultHome Styles */
.exam-result-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
}

.exam-result-image {
    width: 100%;
    height: 55vh;
    object-fit: fill;
    filter: brightness(0.65);
}

/* ExamResults Styles */
.examresult-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
}

.title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: bold;
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
}

.title:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 3px;
    background: #0047AB;
}

.title span:first-child {
    color: #0047AB;
}

.title span:last-child {
    color: #FFD700;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.card:hover {
    transform: translateY(-5px);
}

.image-container {
    width: 100%;
    height: 200px;
    background: #f5f5f5;
    position: relative;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.card-content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
}

.result-text {
    font-size: 1.2rem;
    color: #333;
    font-weight: 500;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.action-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.action-button img {
    width: 20px;
    height: 20px;
}

.download-button {
    padding: 8px 20px;
    background: #008756;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.download-button:hover {
    background: #006c45;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {

    .exam-result-image {
        height: 52vh;
    }

    .cards-container {
        grid-template-columns: repeat(2, 1fr);
    }

    /* .exam-result-container {
        height: 140px;
    } */

    .title {
        font-size: 2rem;
    }

    .image-container {
        height: 150px;
    }

    .result-text {
        font-size: 1rem;
    }

    .download-button {
        width: 100%;
        padding: 10px;
    }
}

@media (max-width:480px) {

    .exam-result-image {
        height: 32vh;
    }

    .cards-container {
        grid-template-columns: 1fr;
    }
}