.success-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
}
  
.success-image {
    width: 100%;
    height: 55vh;
    object-fit: cover;
    filter: brightness(0.65);
}


.storiesimg-container h2{
    text-align: center;
}


.testimonials-container {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .testimonials-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* max-width: 1200px; */
  }
  
  .testimonial-card {
    width: 20%;
    padding: 20px;
    border: 1px solid #1A45AC;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    color: #1A45AC;
  }

  .testimonial-card:hover{
    background-color: #F6C300;
  }

  .testimonial-card img {
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .testimonial-card img:hover {
    transform: scale(1.05);
    opacity: 1;
  }  
  
  .testimonial-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  
  .testimonial-name {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .testimonial-position {
    color: #555;
    font-size: 14px;
  }
  

  @media (max-sith:768px){
    .success-image {
      height: 52vh;
  }
    
  }

  @media (max-width: 480px) {

    .success-image {
      height: 32vh;
  }
    .testimonial-card {
        width: 75%;
    }
  }