/*popup advertisement*/

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-Index: 100000;
}

.popup-container {
  position: relative;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-image {
  width: 25vw;
  /* max-width: 400px; */
  height: 70vh;
  border-radius: 5px;
}

.popup-closeIcon {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 24px;
  color: #ff5c5c;
  cursor: pointer;
}

@media(max-width:768px){

  .popup-container {
    padding: 12px;
  }
  .popup-image {
    width: 40vw;
    height: 60vh;
  }
}


@media(max-width:480px){
  .popup-container {
    padding: 10px;
  }
  .popup-image {
    width: 80vw;
    height: 60vh;
  }
}





/* HomePage.css */
body {
  margin: 0;
  background-color: #f4f4f4;
}

#homepage h2 {
  text-align: center;
}



/* Banner Section - Horizontal Carousel */
.banner-carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  /* Ensure the container takes full width */
  max-width: 100vw;
  /* Use viewport width to cover full screen */
  margin: 0 auto;
}

.carousel-image {
  width: 100vw;
  /* Full width of the viewport */
  height: 55vh;
  /* Maintain aspect ratio */
  /* object-fit: cover; */
  transition: transform 0.3s ease-in-out;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  color: black;
  padding: 15px;
  border-radius: 20px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.carousel-button:hover {
  background-color: gray;
}

/* Upcoming Exams and Notifications Section */
.exams-notifications-section {
  padding: 50px 0;
  background-color: #f5f5f5;
  /* text-align: center; */
}

.exams-notifications-container {
  /* max-width: 1200px; */
  margin: 0 auto;
}

.exams-notifications-section h2 {
  font-size: 2rem;
  margin-bottom: 40px;
  color: #2c3e50;
}

.exams-notifications-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* Upcoming Exams */
.exams-container,
.notificationslist-container {
  width: 50%;
}

.exams-container {
  box-sizing: border-box;
  background-color: #f6c300;
  padding: 20px 20px 20px 80px;
  border-top-left-radius: 100px;
}

.notificationslist-container {
  box-sizing: border-box;
  background-color: #043dec;
  padding: 20px 80px 20px 20px;
}

.exams-container h3,
.notificationslist-container h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: left;
  /* Aligns the headings to the left */
}

.exams-container h3 {
  color: #043dec;
}

.notificationslist-container h3 {
  color: #f6c300;
}

/* Scrollable Content */
.scrollable {
  height: 300px;
  /* Sets a fixed height for the scrollable content */
  overflow: auto;
}

/* Exams Cards */
.exams-grid-container {
  border-radius: 10px;
  padding: 10px;
  background-color: #f6c300;
}

.exams-grid {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: auto;
  /* Use 'auto' or 'thin' */
  scrollbar-color: #043dec #f1f1f1;
}


.exam-card {
  /* background-color: white; */
  color: black;
  /* border-radius: 5px; */
  margin: -9px 10px 10px 0px;
  border-bottom: 3px solid black;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  padding: 15px;
}

.exam-card:hover {
  background-color: #043dec;
  color: #FFFFFF;
  border-radius: 5px;
  /* border-bottom: none; */
}

.exam-card h4,
.notification-item h4 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.exam-card p,
.notification-item p {
  font-size: 1rem;
}

/* Notifications */
.notifications-list-container {
  border-radius: 10px;
  padding: 10px;
  background-color: #043dec;
}

.notifications-list {
  list-style: none;
  /* padding: 0; */
  box-sizing: border-box;
}

.notification-item {
  /* background-color: white; */
  color: white;
  padding: 15px;
  /* border-radius: 5px; */
  margin: -9px 10px 10px 0px;
  font-size: 1rem;
  border-bottom: 3px solid white;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: background-color 0.3s ease;
  cursor: pointer;
}

/* Style the scrollbar track */

.notifications-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: auto;
  scrollbar-color: #f6c300 #f1f1f1;
}


.notification-item:hover {
  background-color: #f6c300;
  border-radius: 5px;
  /* border-bottom: none; */
  color: #333;
}

/* Scrollbar customization */
.scrollable::-webkit-scrollbar {
  width: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #bdc3c7;
  border-radius: 4px;
}

/* /ourservices/ */
.services-section {
  background-color: #ffffff;
  padding: 20px;
}

.our-services {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.service-cards-container {
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  /* Allows the arrows to be visible outside the container */
  width: 85%;
  /* Adjust the width as per your requirement */
  background-color: #fff;
  /* Optional background for the container */
  border-radius: 10px;
  /* Optional rounded corners */
}

.arrow {
  background: #1a45ac;
  color: #fff;
  border: none;
  padding: 15px;
  /* Increased padding for better visibility */
  font-size: 20px;
  /* Increased font size for larger arrows */
  cursor: pointer;
  z-index: 10;
  /* Ensure the arrows are above the cards */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* Make the arrows circular */
  width: 30px;
  /* Set a specific width for the arrows */
  height: 30px;
  /* Set a specific height for the arrows */
}

.arrow.left {
  z-index: 1002;
  position: absolute;
  left: -50px;
  /* Move left arrow outside the cards */
  top: 50%;
  transform: translateY(-50%);
}

.arrow.right {
  z-index: 1002;
  position: absolute;
  right: -40px;
  /* Move right arrow outside the cards */
  top: 50%;
  transform: translateY(-50%);
}

.service-cards-wrapper {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0px;
  scroll-snap-type: x mandatory;
  gap: 20px;

  /* Hide scrollbars */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
  scrollbar-width: none;
  /* For Firefox */
}

/* Hide scrollbar for webkit browsers (Chrome, Safari, etc.) */
.service-cards-wrapper::-webkit-scrollbar {
  display: none;
}

.service-card {
  flex: none;
  width: 200px;
  text-align: center;
  border-radius: 10px;
  scroll-snap-align: start;
  transition: transform 0.3s ease, background-color 0.3s ease;
  padding: 20px;
}

.service-card:hover {
  transform: scale(1.05);
  /* Enlarge the card */
  background-color: #f6c300;
  /* Change background color on hover */
}

.service-card img {
  width: 50%;
  /* border-radius: 10px 10px 0 0; */
}

.service-card h4 {
  margin-top: 10px;
  color: #333;
  font-size: 0.9em;
}

.activities-section {
  background-color: #f6f6f6;
  padding: 50px;
}

.activity-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
}

.activity-card {
  flex: none;
  width: 250px;
  text-align: center;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #f6c300;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.activity-card:hover {
  transform: scale(1.05);
  /* Slightly enlarges the card */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Adds a stronger shadow for elevated effect */
}

.activity-card img {
  width: 20%;
  margin: 15px;
}

.activity-card h4 {
  text-align: left;
  color: #1a45ac;
  font-size: 0.9em;
  margin-top: 10px;
}

.activity-card p {
  text-align: left;
  font-size: 0.8em;
  color: #333;
}

/* Registrations section styling */
.registrations-section {
  padding: 50px;
  background-color: #fff;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registrations-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  /* Reduced gap for better fit on smaller screens */
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  /* Limit max width for larger screens */
  margin: auto;
}

.registrations-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, border 0.3s;
  flex: none;
  width: 18%;
  text-align: center;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #f6c300;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.registrations-card h3{
  font-weight: 500;
}

.registrations-card:hover {
  background-color: #f6c300;
  border: 1px solid #1a45ac;
}

.registrations-card img {
  padding: 10px;
  width: 40px;
  /* Smaller icon size for better layout on small screens */
  border-radius: 50%;
  margin: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.registration-value {
  font-size: 1.2rem;
  /* Adjusted font size for visibility */
  color: #1a45ac;
  margin: 8px 0;
}

/* Large screens */
@media (min-width: 1024px) {
  .registrations-section {
    background-image: url("./bg_css.png");
  }
  .feedback-section {
    background-image: url("./bg1_css.png");
    background-repeat: no-repeat;
    background-position-x: right;
  }
}

/* Medium screens (tablets) */
@media (max-width: 1023px) {
  .registrations-cards-wrapper {
    gap: 10px;
    width: 90%;
  }

  .registration-value {
    font-size: 1.1rem;
  }
}

/* Small screens (large mobile) */
@media (max-width: 768px) {


  .registrations-section {
    background-position: left top;
    background-size: auto 70%;
  }

  .registrations-cards-wrapper {
    width: 100%;
    padding: 10px;
  }

  .registrations-card {
    padding: 10px;
    width: 135px;
  }

  .registrations-card h3 {
    font-size: 15px;
  }

  .registration-value {
    font-size: 1rem;
  }
}

/* Extra small screens (mobile) */
@media (max-width: 480px) {
  .registrations-section {
    background-position: left top;
    background-size: auto 50%;
  }

  .registrations-cards-wrapper {
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
  }

  .registrations-card {
    padding: 8px;
    width: 180px;
  }

  .registrations-card h3 {
    font-size: 15px;
    font-weight: 500;
  }

  .registration-value {
    font-size: 0.9rem;
  }

  .registrations-card img {
    width: 35px;
  }
}



/* class section */
.classes-section {
  padding: 50px;
}

.classes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  width: 100%;
}

.class-cards-container {
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.class-cards-wrapper {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 10px;
  scroll-snap-type: x mandatory;
  gap: 20px;
  width: 100%;

  /* Hide scrollbars */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.class-cards-wrapper::-webkit-scrollbar {
  display: none;
}

.class-card {
  flex: none;
  width: 300px;
  text-align: center;
  background: #f0f0f047;
  border-radius: 10px;
  scroll-snap-align: center;
  padding: 10px;
}

.class-card h3 {
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #1a45ac;
}

.iframe-container {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 10px;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Navigation buttons */
.classes-navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.classes-nav-btn {
  background: white;
  color: #1a45ac;
  border: none;
  padding: 12px 16px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.classes-nav-btn:hover {
  background: #f9f9f9;
}

@media (max-width: 480px) {
  .class-card {
    width: 210px;
  }

  .class-card h3 {
    font-size: 13px;
  }
}





/* feedback section */
.feedback-section {
  background-color: #ffffff;
  padding: 50px;
}

.feedback-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-cards-container {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.feedback-cards-wrapper {
  align-items: center;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 10px;
  scroll-snap-type: x mandatory;
  gap: 20px;
  width: 650px;

  /* Hide scrollbars */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.feedback-card {
  display: flex;
  flex: none;
  scroll-snap-align: start;
  flex-direction: column;
  align-items: center;
  width: 550px;
  background-color: #1a45ac;
  color: #ffffff;
  border-radius: 10px;
  padding: 50px;
  text-align: center;

}

.quotation-icons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quote-icon-left,
.quote-icon-right {
  color: #ffffff;
  /* Adjust color as needed */
  font-size: 1.5em;
  /* Adjust size as needed */
  z-index: 1002;
}

.quote-icon-left {
  margin-right: 10px;
  align-self: flex-start;
  /* Align at the start of the paragraph */
}

.quote-icon-right {
  margin-left: 10px;
  align-self: flex-end;
  /* Align at the end of the paragraph */
}


.feedback-card p {
  font-size: 16px;
  margin: 10px 20px;
  text-align: justify;
  color: #ffffff;
}


.feedback-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.feedback-arrows {
  display: flex;
  justify-content: space-between;
  width: 100%; 
}

.feedback-nav-btn {
  background: #ffffff00;
  color: rgb(255, 255, 255);
  border: none;
  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 20px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .feedback-card {
    width: 450px;
    padding: 40px;
  }

  .feedback-cards-wrapper {
    width: 515px;
  }

}

@media (max-width: 768px) {
  .feedback-card {
    width: 350px;
    padding: 30px;
  }

  .feedback-cards-wrapper {
    width: 400px;
  }
}

@media (max-width: 480px) {
  .feedback-card {
    width: 215px;
  }

  .feedback-cards-wrapper {
    width: 272px;
  }

  .feedback-card p {
    font-size: 12px;
    color: #ffffff;
  }

  .feedback-section {
    padding: 50px;
  }

}



@media (max-width:321px){
  .feedback-card {
    width: 180px;
    padding: 20px;
  }

}



/* App showing */
.playapp-section {
  padding: 50px;
  background-color: #F7F7F7;
}

.playapp-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #FFFFFF;
  border: 4px solid #f6c300;
  border-radius: 0px 200px;
  padding: 20px;
}

/* Left container */
.playapp-container-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.playapp-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.playapp-options p {
  margin: 0;
  color: #ffffff;
}

.playapp-option-buttons {
  padding: 15px 30px;
  border-radius: 20px;
  background-color: #1a45ac;
  color: #ffffff;
  font-size: 1rem;
}

/* Play store image container */
.play-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playstore-image {
  width: 200px;
  height: auto;
  margin-top: 20px;
}

/* Right container */
.playapp-container-right {
  width: 50%;
  display: flex;
  justify-content: center;
}

.mobile-image {
  border-radius: 0 30% 0 0;
  width: 100%;
  max-width: 400px;
  /* Limit max width for larger screens */
  height: auto;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .playapp-container {
    flex-direction: column;
    border-radius: 0px;
    /* Adjust border radius for smaller screens */
  }

  .playapp-container-left,
  .playapp-container-right {
    width: 100%;
    /* Full width on smaller screens */
    text-align: center;
  }

  .playapp-container {
    border-radius: 0px 200px;
  }

  .playstore-image {
    width: 150px;
    /* Smaller playstore icon for smaller screens */
  }

  .playapp-option-buttons {
    padding: 8px 20px;
    font-size: 0.9rem;
    /* Adjust font size */
  }
}

@media (max-width: 480px) {
  .playapp-section {
    padding: 30px 15px;
    /* Reduce padding for very small screens */
  }

  .playapp-option-buttons {
    padding: 6px 15px;
    font-size: 0.8rem;
  }

  .playstore-image {
    width: 120px;
    /* Even smaller for mobile devices */
  }

  .mobile-image {
    width: 80%;
    /* Reduce image size for narrow screens */
  }

  .playapp-container {
    border-radius: 0px 100px;
  }
}

/* Responsive styles */

@media (max-width: 480px) {
  .activity-card {
    width: 90%;
    padding: 15px;
  }

  .activity-card h3 {
    font-size: 1em;
  }

  .activity-card p {
    font-size: 0.85em;
  }
}


@media (max-width: 768px) {
  .exams-grid {
    flex-direction: column;
    /* align-items: center; */
  }

  /* .exam-card {
    width: 100%;
  } */

  .notifications-list {
    margin: 0;
  }

  .exams-notifications-content {
    flex-direction: column;
  }

  .exams-container,
  .notificationslist-container {
    width: 99%;
  }

  .exams-container {
    padding: 30px 30px 30px 30px;
  }

  .notificationslist-container {
    padding: 4px;
  }

  .exams-container h3,
  .notificationslist-container h3 {
    text-align: center;
  }

  .exams-notifications-section h2 {
    font-size: 1.5rem;
  }

  .exams-container h3,
  .notificationslist-container h3 {
    font-size: 1.3rem;
  }

  .carousel-button {
    padding: 5px;
    font-size: 16px;
  }

  .carousel-image {
    height: auto;
    /* Adjust height for smaller screens */
  }

  .activity-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    align-items: center;
  }

  .exam-card h4,
  .notification-item h4 {
    font-size: 0.85rem;
    margin-bottom: 10px;
  }

  .exam-card p,
  .notification-item p {
    font-size: 0.85rem;
  }

}

@media (max-width:480px) {

  .exams-container {
    padding: 30px 0px 30px 0px;
  }

  .notificationslist-container {
    padding: 30px 0px 30px 0px;
  }

  .exams-container h3,
  .notificationslist-container h3 {
    text-align: center;
  }

  .exams-container h3,
  .notificationslist-container h3 {
    font-size: 1.0rem;
  }

  /* .exam-card h4,
  .notification-item h4 {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }

  .exam-card p,
  .notification-item p {
    font-size: 0.7rem;
  } */
}

@media (max-width: 576px) {
  .exams-notifications-content {
    flex-direction: column;
  }

  .exams-container,
  .notificationslist-container {
    width: 100%;
  }

  .service-cards-container {
    width: 55%;
  }

  .service-card {
    width: 165px;
  }
}

@media (max-width: 375px) {
  .service-card {
    width: 130px;
  }
}

@media (max-width: 320px) {
  .service-card {
    width: 114px;
  }
}



.carousel-pagination {
  position: absolute;
  bottom: 20px;
  /* Adjust the distance from the bottom of the banner */
  left: 50%;
  /* Center align the pagination */
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  /* Add spacing between pagination dots */
  z-index: 10;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-dot.active {
  background-color: #333;
}

@media (max-width:480px) {
  .carousel-pagination {
    gap: 0px;
  }
}
