.footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #D9D9D9;
  color: #000000;
  padding: 0px 50px;
  justify-content: space-between;
}

.footer p,a{
  font-size: 0.9rem;
}

/* .footer-top{
  background-color: #000000;
  display: flex;
  justify-content: space-around;
  color: #ffffff;
} */

.footer-top {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  font-size: 1rem;
}

.footer-top p {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: white;
}

/* Styling for icon and text alignment */
.footer-top p svg {
  margin-right: 10px;
}

.footer-bottom{
  text-align: center;
  font-size: 0.9rem;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .footer-top {
    flex-direction: column;
    text-align: center;
  }

  .footer-top p {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .footer-top {
    padding: 15px;
  }

  .footer-top p {
    font-size: 0.9rem;
  }
  
  .footer{
    padding: 0px;
  }
}


.footer-bottom{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /* padding:15px 0px; */
  border-top: 1px solid rgb(94, 92, 92);
  background-color: #D9D9D9;
  color: #000000;
}

.footer-bottom p{
    margin: 0%;

}

.footer-section {
  min-width: 200px;
  margin: 10px;
}

.footer-section h3{
    color: #1A45AC;
    text-align: left;
}

.footer-title {
  background: linear-gradient(90deg, #1a45ac 0%, #f6c300 50%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: left;
}

.footer-address{
    text-align: left;
}

.footer-address,
.footer-social,
.footer-section ul,
.footer-section p {
  color: #000000;
}

.footer-social .social-icons {
    display: flex;
    gap: 10px;  /* Adjust space between icons */
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .social-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: white;
    border-radius: 50%;
    border: 3px solid white;
  }

  .social-icon:hover {
    transform: scale(1.1);
}
  
.footer-section ul {
    list-style: inside; /* Ensures the dot appears on the left inside the list item */
    color: #f6c300;
    padding: 0;
    text-align: left;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
  }
  

  .footer-section a{
    color: #000000;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: none;
  }
  
  .footer-section ul li.phone {
    display: flex;
    align-items: center; /* Aligns the content to the center vertically */
    list-style-position: inside; /* Ensures the dot is part of the list item */
    list-style-type: disc; /* Shows the yellow dot */
  }
  
  .phone-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .footer-section ul li .phone-numbers {
    display: flex;
    flex-direction: column;
    margin-left: 20px; /* Space between phone label and numbers */
  }
  
  .footer-section ul li .phone-numbers span {
    margin-bottom: 5px;
  }
  
  .terms-privacy{
    display: flex;
    list-style: none;
    gap: 20px;
    cursor: pointer;
  }

  .terms-privacy li a{
    text-decoration: none;
    color: #000000;
  }

  @media (max-width:480px) {
    .terms-privacy{
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 15px;
      margin-left: -40px;
    }
  }



  .terms-privacy-pop-up {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000; /* Ensure it overlays other content */
  }
  
  .pop-up-content {
    background: #ffffff;
    padding: 20px;
    /* border-radius: 8px; */
    width: 90%;
    max-width: 600px;
    text-align: left;
    max-height: 80%;
    overflow-y: auto; /* Enable scrolling for lengthy content */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    font-family: Arial, sans-serif;
  }
  
  .pop-up-text p {
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    white-space: pre-line; /* Preserve line breaks in text */
  }
  
  .pop-up-checkbox {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .pop-up-checkbox input {
    margin-right: 10px;
    cursor: pointer;

  }
  
  .pop-up-checkbox label {
    font-size: 14px;
    color: #333;
  }
  
  .pop-up-ok-button {
    margin-top: 20px;
    background-color: #1a45ac;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .pop-up-ok-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pop-up-ok-button:hover:not(:disabled) {
    background-color: #164099;
  }

  .pop-up-cancel-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 20px;
  }
  
  .pop-up-cancel-button:hover {
    background-color: #d32f2f;
  }