/* App.css */
.material-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: block;
}

/* Header Styles */
header {
  text-align: center;
  margin-bottom: 40px;
}

.blue-text {
  color: #0066cc;
  font-weight: bold;
}

.yellow-text {
  color: #ffd700;
  font-weight: bold;
}

.description {
  color: #333;
  max-width: 800px;
  margin: 20px auto;
  line-height: 1.6;
}

/* Search Section Styles */
.search-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.search-input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
}

.cart-icon {
  position: relative;
  font-size: 24px;
  cursor: pointer;
}

.cart-icon a{
  text-decoration: none;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #0066cc;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

/* Materials Grid Styles */
/* Grid Container */
.materials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Card Container */
.materials-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  position: relative;
  padding: 10px;
}

/* Hover Effect */
.materials-card:hover {
  transform: scale(1.05);
}

/* Badge */
.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  background: #ffd700;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  border-radius: 20px;
  text-transform: uppercase;
}

/* Card Image */
.card-image {
  width: 100%;
  height: 250px;
  object-fit: contain;
  /* border-bottom: 1px solid #ddd; */
}

/* Card Content */
.materials-names {
  padding: 15px;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.card-subtopic {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  margin: 3px 0;
  line-height: 1.5; /* Adjust for readability */
}

.card-subtopic {
  display: block;
}

.card-subtopic + .card-price-wrapper {
  margin-top: 10px;
}

/* Card Price Wrapper */
.card-price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.card-price {
  font-size: 15px;
  font-weight: bold;
  color: #ffd700;
}

.stock-badge {
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px;
  text-transform: uppercase;
}

.stock-badge.in-stock {
  color: #28a745; /* Green for In Stock */
}

.stock-badge.out-of-stock {
  color: #dc3545; /* Red for Out of Stock */
}

/* Card Buttons */
.card-buttons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.btn {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.add-to-cart {
  background: #fff;
  color: #000;
  border: 1px solid #ddd;
  border-color: #007bff;
}

.add-to-cart:hover {
  background: #0066cc;
  color: white;
}

.go-to-cart {
  background-color: #28a745; /* Green color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.go-to-cart:hover {
  background-color: #218838; /* Darker green on hover */
}

.buy-now {
  background: #0066cc;
  color: #fff;
}

.buy-now:hover {
  background: #0066cc6b;
  color: #000;
  border: 1px solid #ddd;
  border-color: #007bff;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.page-number,
.prev-page,
.next-page {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  border-radius: 4px;
}

.page-number.active,
.prev-page.active,
.next-page.active {
  background-color: #007bff;
  color: white;
}

.page-number:hover,
.prev-page:hover,
.next-page:hover{
  background-color: #ffd700;
}

.page-number.active {
  font-weight: bold;
  border-color: #007bff;
}

.page-number:disabled,
.prev-page:disabled,
.next-page:disabled {
  background-color: #f8f9fa;
  color: #ccc;
  cursor: not-allowed;
}


/* Newsletter Section Styles */
.newsletter {
  text-align: center;
  margin: 60px 0;
}

.newsletter-description {
  color: #333;
  font-style: italic;
  margin: 20px 0;
}

.subscribe-btn {
  background-color: #0066cc;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Contact Section Styles */
/* .contact-section {
  background-size: cover;
  background-position: center;
  padding: 60px 20px;
  margin-top: 60px;
}

.contact-overlay {
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

.material-container .contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form textarea {
  height: 100px;
  resize: vertical;
}

.submit-btn {
  background-color: #ffd700;
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

/* Responsive Design */
@media (max-width: 768px) {
  .materials-grid {
    grid-template-columns: repeat(2,1fr);
  }

  .search-section {
    flex-direction: column;
  }

  .search-input {
    width: 100%;
  }

  .contact-overlay {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .materials-grid {
    grid-template-columns: 1fr;
  }
  .card-buttons {
    flex-direction: column;
  }

  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    width: 100%;
  }
}