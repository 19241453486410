/* QuestionPaper.css */
.previousquestions-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    position: relative; /* Allow positioning of children */
}

.previous-question-title { 
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: bold;
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
}

.previous-question-title:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 3px;
    background: #0047AB;
}

.previous-question-title span:first-child {
    color: #0047AB;
}

.previous-question-title span:last-child {
    color: #FFD700;
}

.number-buttons {
    display: flex; /* Use flexbox to arrange buttons in a row */
    justify-content: center; /* Center the buttons */
    margin-top: 30px; /* Add some space above the buttons */
}

.number-buttons button {
    background: #FFD700; /* Yellow color */
    color: black; /* Black text color */
    border: none;
    border-radius: 50%; /* Make the button circular */
    width: 40px; /* Decreased width */
    height: 40px; /* Decreased height */
    font-size: 1rem; /* Decreased font size */
    margin: 0 5px; /* Space between buttons */
    cursor: pointer;
    transition: background 0.3s, transform 0.3s; /* Smooth transition for hover effect */
}

.number-buttons button:hover {
    background: #0047AB; /* Blue color on hover */
    color: white; /* White text color on hover */
    transform: scale(1.1); /* Slightly increase size on hover */
}

.question-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.question-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.question-card:hover {
    transform: translateY(-5px);
}

.question-image-container {
    width: 100%;
    height: 200px;
    background: #f5f5f5;
    position: relative;
    overflow: hidden; /* Ensure that any overflow is hidden */
}

.question-image-container img {
    width: 100%; /* Make the image take the full width */
    height: 100%; /* Make the image take the full height */
    object-fit: contain; /* Ensure the image covers the container */
    position: absolute; /* Position it absolutely */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
}

.question-card-content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
}

.question-text {
    font-size: 1.2rem;
    color: #333;
    font-weight: 500;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.action-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.action-button img {
    width: 20px;
    height: 20px;
}

.download-button {
    padding: 8px 20px;
    background: #008756;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.download-button:hover {
    background: #006c45;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .previous-question-title {
        font-size: 2rem;
    }

    .question-cards-container {
        grid-template-columns: repeat(2,1fr); /* Single column layout for mobile */
    }

    .number-buttons button {
        font-size: 0.9rem; /* Adjust font size for mobile */
        width: 35px; /* Smaller width for mobile */
        height: 35px; /* Smaller height for mobile */
    }
    .question-image-container {
        height: 150px; /* Adjust height for mobile for better appearance */
    }
    
    .question-text {
        font-size: 1rem; /* Smaller font size for mobile */
    }
}

@media (max-width:480px){
    .question-cards-container {
        grid-template-columns: 1fr; /* Single column layout for mobile */
    }

    .question-image-container img {
        object-fit: contain; /* Ensure the image covers the container */
    }
}