.profile-card {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-header {
  margin-bottom: 20px;
}

.profile-initial {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
  margin: 0 auto 10px;
}


.profile-header h2 {
  font-size: 20px;
  margin: 0;
}

.profile-header p {
  font-size: 14px;
  color: #888;
}

.profile-details {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.profile-details li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.profile-name{
  margin-right: 10px;
}

.profile-div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-icon {
  color: #1a73e8;
  cursor: pointer;
  font-size: 14px;
}

.edit-icon:hover {
  color: #004aad;
}


@media (max-width:480px){
  .profile-details li {
    font-size: 12px;
  }
}




/* Input Fields Styling */
.profile-div input {
  width: 80%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.profile-div input:focus {
  border-color: #1a73e8;
}

/* Button Styling */
.profile-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.profile-actions button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.profile-actions button:hover {
  background-color: #1a73e8;
  color: white;
}

.profile-actions button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mobile Responsiveness for Input Fields */
@media (max-width: 480px) {

  .profile-card {
    max-width: 250px;
  }

  .profile-div{
    justify-content: start;
    gap: 15px;
  }

  .profile-div input {
    width: 100%;
    font-size: 12px;
  }

  .profile-actions button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
