/* Styles for ourcenters component */
.ourcenters-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
  }
  
  .ourcenters-image {
    width: 100%;
    height: 55vh;
    object-fit: cover;
    filter: brightness(0.65);
  }
  
  .ourcenters-text-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    animation: slideIn 1s ease-out forwards;
  }
  
  .ourcenters-content {
    max-width: 800px;
  }
  
  .ourcenters-title {
/*     color: rgb(0, 0, 0); */
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-left: 10%;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .ourcenters-title:hover {
    transform: scale(1.05);
    color: #f0f0f0;
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translate(-100%, -50%);
    }
    100% {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
  
  .map-containers{
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 15px;
      padding: 50px;
  }
  
  .map-cards{
      padding: 10px;
      border: 2px solid #1A45AC;
  }

  .centers-pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .centers-pagination-btn {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    background-color: #f6c300;
    color: #333;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .centers-pagination-btn.active,
  .centers-pagination-btn:hover {
    background-color: #1a45ac;
    color: #fff;
  }
  

  @media (max-width:768px){

    .ourcenters-image {
      height: 52vh;
    }
  }
  
  @media (max-width: 480px) {
    .ourcenters-image {
      height: 32vh;
    }
      .map-containers{
          display: grid;
          grid-template-columns: 1fr;
          gap: 40px;
          padding: 20px;
      }
  }
  
  
  
  
