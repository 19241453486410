.outlet {
    padding-top: 65px;
    min-height: 50vh;
}

.outlet.no-navbar {
    padding-top: 0; /* Remove padding when there's no navbar */
}

@media (max-width: 1023px) {
    .outlet {
        padding-top: 45px;
        min-height: 100vh;
    }
}

.outlet.no-navbar {
    padding-top: 0;
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .outlet {
        padding-top: 125px;
        min-height: 100vh;
    }
}

.outlet.no-navbar {
    padding-top: 0;
}
