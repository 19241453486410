.sticky-icon {
  z-index: 1000;
  position: fixed;
  top: 40%;
  right: 0%;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.sticky-icon a {
  display: flex;
  align-items: center;
  /* Aligns icon and text horizontally and centers vertically */
  transform: translate(110px, 0px);
  border-radius: 50px 0 0 50px;
  text-align: left;
  margin: 2px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  font-size: 15px;
  font-family: 'Oswald', sans-serif;
  transition: all 0.8s;
}

.sticky-icon a:hover {
  color: #FFF;
  transform: translate(0px, 0px);
}

.sticky-icon a:hover i {
  transform: rotate(360deg);
}

.Facebook {
  background-color: #2C80D3;
  color: #FFF;
}

.Whatsapp {
  background-color: rgb(35, 180, 35);
  color: #FFF;
}

.Youtube {
  background-color: #fa0910;
  color: #FFF;
}

.Twitter {
  background-color: #000000;
  color: #FFF;
}

.Telegram {
  background-color: #0170b1;
  color: #FFF;
}

.Instagram {
  background-color: #FD1D1D;
  color: #FFF;
}

.Google {
  background: linear-gradient(90deg, yellow, rgb(242, 52, 52), rgb(92, 92, 241), rgb(41, 162, 41));
  color: #FFF;
}

.sticky-icon a i {
  background-color: #FFF;
  height: 40px;
  width: 40px;
  color: #000;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  transition: all 0.5s;
}

/* Media query for mobile view */
@media (max-width: 480px) {
  /* .sticky-icon {
    left: 0;
    right: auto;
  }

  .sticky-icon a {
    flex-direction: row-reverse;
    transform: translate(-110px, 0);
    border-radius: 0 50px 50px 0;
    padding-right: 0;
  }

  .sticky-icon a i {
    margin-left: 20px;
    margin-right: 0;
  }

  .icon-sticky {
    margin-left: 10px;
  } */

  .sticky-icon {
    width: 40px;
  }

  .sticky-icon a {
    transform: translate(2px, 0px);
  }

  .sticky-icon a:hover {
    transform: translate(2px, 0px);
  }
}