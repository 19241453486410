.address-container {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.address-container h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.address-form {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.order-summary {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.order-summary h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.order-item {
  margin-bottom: 15px;
  display: flex;
}

.order-item-image {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
}

.order-item h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 5px;
}

.order-item p {
  font-size: 1rem;
  color: #666;
}

.checkout-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.total-amount-check{
  text-align: center;
  background-color: rgb(215, 236, 254);
  padding: 10px;
}
.total-amount-check h4{
  margin: 0;
}

.cancel-button {
  padding: 12px 30px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #c82333;
}

/* Update existing pay-button styles if needed */
.pay-button {
  padding: 12px 30px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #218838;
}

.pay-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .total-amount-check h4{
    margin: 0;
    font-size: 0.8rem;
  }
}