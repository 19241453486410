.marquee-bar {
  position: relative;
  background-color: #007bff; /* Optional background color */
  padding: 7px 0; /* Space for the marquee */
  font-size: 0.9rem; /* Adjust font size for readability */
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  z-index: 1; /* To ensure it stays above other content */
  overflow: hidden; /* Ensure text stays within bounds */
  display: flex; /* Ensures proper alignment */
}

.scrolling-item-container {
  display: inline-flex; /* Aligns the button and link horizontally */
  align-items: center; /* Vertically aligns items */
  gap: 10px; /* Spacing between the button and the link */
}

.extra-spacing {
  margin-left: 40px; /* Adds extra space between the first and second links */
}

.click-me-button {
  background-color: #F6C300;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: pointer;
  animation: blink 1s infinite; /* Adds blinking animation */
}

@keyframes blink {
  0%,
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.scrolling-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  margin-right: 30px;
}

.scrolling-link:hover {
  text-decoration: underline;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .marquee-bar {
    margin-top: 10px;
  }
  .scrolling-item-container {
    gap: 5px; /* Reduce gap between items on smaller screens */
  }

  .extra-spacing {
    margin-left: 20px; /* Reduce spacing for mobile */
  }

  .marquee-bar {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
  }
}
