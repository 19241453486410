.scroll-to-top {
    position: fixed; /* Fixed position */
    bottom: 90px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
    background-color: #007BFF; /* Background color */
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 15px; /* Padding */
    font-size: 18px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex; /* Flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    transition: background-color 0.3s; /* Transition effect */
    z-index: 1000;
  }
  
  .scroll-to-top:hover {
    background-color: #0056b3; /* Darker background on hover */
  }
  
  @media (max-width:480px){
    .scroll-to-top {
      bottom: 30px; /* Distance from the bottom */
    }
  }