/* SingleBlog.css */
.single-blog-container {
  display: flex;
  gap: 20px;
  max-width: 1200px;
  margin: 20px auto;
  /* padding: 20px; */
  width: 100%;
  width: 90%
}

.single-blog {
  /* flex: 3; */
  width: 70%;
  padding: 20px;
}

.single-blog h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.single-blog img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.single-blog .meta {
  font-size: 0.9rem;
  color: #666;
  margin-top: 20px;
}

.single-blog-right {
  width: 30%;
  /* flex: 1; */
  padding: 20px;
  height: fit-content;
}

/* Search Bar */
.blog-search-bar {
  margin-bottom: 20px;
  position: relative;
  max-width: 600px;
  margin: 30px auto;
  text-align: center;
}

.blog-search-icon {
  margin: 0 10px;
  right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.blog-search-bar form {
  display: flex;
  align-items: center;
}

.blog-search-bar input {
  box-sizing: border-box;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

.blog-search-bar button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.blog-search-bar button:hover {
  background-color: #0056b3;
}

/* Recent Blogs */
.recent-blogs h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: justify;
}

.recent-blogs ul {
  list-style: none;
  padding: 0;
}

.recent-blogs li {
  margin-bottom: 15px;
  border-bottom: 1px solid black;
}

.recent-blogs li a {
  text-decoration: none;
  color: #0077cc;
  font-weight: bold;
}

.recent-blogs li a:hover {
  text-decoration: underline;
}

.recent-blogs li p {
  font-size: 0.9rem;
  color: #666;
  margin: 5px 0;
}

.single-blog-content {
  width: 100%;

}

.single-blog div ul,
.single-blog div ol,
.single-blog div ul li,
.single-blog div ol li,
.single-blog div p {
  font-family: 'Poppins', sans-serif;
  font-size: 15px !important;
  text-align: justify;
}


.single-blog div span,
.single-blog div h1,
.single-blog div h2,
.single-blog div h3,
.single-blog div h4,
.single-blog div h5,
.single-blog div h6 {
  font-family: 'Poppins', sans-serif;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .single-blog-container {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }

  .single-blog-right {
    box-sizing: border-box;
    width: 100%;
  }

  .single-blog {
    width: 100%;
    padding: 0px;
  }

  .single-blog img {
    height: 250px;
  }

  .single-blog h1 {
    font-size: 1.8rem;
  }

  /* Ensure content aligns properly */
  .single-blog div[dangerouslySetInnerHTML] {
    width: 100%;
    overflow-x: auto;
    /* Allow horizontal scrolling if content overflows */
  }

  .single-blog div[dangerouslySetInnerHTML] img {
    max-width: 100%;
    /* Ensure images don't overflow */
    height: auto;
  }

  .single-blog div[dangerouslySetInnerHTML] p,
  .single-blog div[dangerouslySetInnerHTML] h1,
  .single-blog div[dangerouslySetInnerHTML] h2,
  .single-blog div[dangerouslySetInnerHTML] h3,
  .single-blog div[dangerouslySetInnerHTML] ul,
  .single-blog div[dangerouslySetInnerHTML] ol {
    width: 100%;
    word-wrap: break-word;
    /* Break long words to prevent overflow */
  }
}


.single-blog div[dangerouslySetInnerHTML] img {
  width: 100%;
  height: 100%;
}

/* Ensure content aligns properly */
.single-blog div[dangerouslySetInnerHTML] {
  width: 100% !important;
  overflow-x: auto !important;
  /* Allow horizontal scrolling if content overflows */
}

.single-blog div[dangerouslySetInnerHTML] img {
  max-width: 100% !important;
  /* Ensure images don't overflow */
  height: auto !important;
}

.single-blog div[dangerouslySetInnerHTML] p,
.single-blog div[dangerouslySetInnerHTML] h1,
.single-blog div[dangerouslySetInnerHTML] h2,
.single-blog div[dangerouslySetInnerHTML] h3,
.single-blog div[dangerouslySetInnerHTML] ul,
.single-blog div[dangerouslySetInnerHTML] ol {
  width: 100% !important;
  word-wrap: break-word !important;
  /* Break long words to prevent overflow */
}

/* Explicitly style ul and li inside dangerouslySetInnerHTML */
.single-blog div[dangerouslySetInnerHTML] ul,
.single-blog div[dangerouslySetInnerHTML] ol {
  list-style-position: inside !important;
  /* Ensure bullets/numbers are inside the padding */
  margin-left: 20px !important;
  /* Add indentation */
  padding-left: 20px !important;
  /* Add padding */
  width: 100% !important;
  /* Ensure lists take full width */
}

.single-blog div[dangerouslySetInnerHTML] li {
  margin-bottom: 10px !important;
  /* Add spacing between list items */
  line-height: 1.6 !important;
  /* Improve readability */
  word-wrap: break-word !important;
  /* Force text wrapping */
  white-space: normal !important;
  /* Ensure text wraps */
  overflow-wrap: break-word !important;
  /* Break long words */
}

/* Ensure ordered lists (ol) display numbers correctly */
.single-blog div[dangerouslySetInnerHTML] ol {
  list-style-type: decimal !important;
  /* Add numbers for ordered lists */
}

/* Ensure unordered lists (ul) display bullets correctly */
.single-blog div[dangerouslySetInnerHTML] ul {
  list-style-type: disc !important;
  /* Add bullet points */
}

/* Existing styles remain the same */

/* Target lists within the dynamic content */
.single-blog div ul,
.single-blog div ol {
  max-width: 100%;
  padding-left: 1.5rem;
  /* Give some space for list markers */
  margin: 1rem 0;
  font-size: smaller;
}

.single-blog div li {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 0.5rem;
}


@media screen and (max-width: 768px) {

  .single-blog div ul,
  .single-blog div ol,
  .single-blog div ul li,
  .single-blog div ol li,
  .single-blog div p {
    font-size: 15px !important;
  }

  .single-blog div h1 {
    font-size: 26px !important;
  }

  .single-blog div h2 {
    font-size: 23px !important;
  }

  .single-blog div h3 {
    font-size: 20px !important;
  }

  .single-blog div h4 {
    font-size: 18px !important;
  }

  .single-blog div h5 {
    font-size: 15px !important;
  }

  .single-blog div h6 {
    font-size: 12px !important;
  }
}


@media screen and (max-width: 1024px) {

  .single-blog div ul,
  .single-blog div ol,
  .single-blog div ul li,
  .single-blog div ol li,
  .single-blog div p {
    font-size: 15px !important;
    /* text-align: justify; */
  }

  .single-blog div h1 {
    font-size: 26px !important;
  }

  .single-blog div h2 {
    font-size: 23px !important;
  }

  .single-blog div h3 {
    font-size: 20px !important;
  }

  .single-blog div h4 {
    font-size: 18px !important;
  }

  .single-blog div h5 {
    font-size: 15px !important;
  }

  .single-blog div h6 {
    font-size: 12px !important;
  }
}



@media screen and (max-width: 480px) {

  .single-blog div span,
  .single-blog div h1,
  .single-blog div h2,
  .single-blog div h3,
  .single-blog div h4,
  .single-blog div h5,
  .single-blog div h6 {
    padding-right: 0px !important;
  }

  .single-blog div ul,
  .single-blog div li {
    margin: 0px !important;
  }

}