/* Styles for ChatBanner component */
.chairman-home-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
    /* padding: 2.5rem 1.5rem; */
    /* width: 100%; */
    /* height: 150px; */
}
  
.chairman-home-image {
    width: 100%;
    height: 55vh;
    object-fit: fill;
    filter: brightness(0.65);
  }

  .chairman-home-text-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    animation: slideIn 1s ease-out forwards;
  }

.chairman-home-content {
    max-width: 800px;
}
  
.chairman-home-title {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-left: 10%;
    transition: transform 0.3s ease, color 0.3s ease;
  }

  
.chairman-home-title:hover {
    transform: scale(1.05);
    color: #f0f0f0;
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translate(-100%, -50%);
    }
    100% {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }


/* ChairmanMessage.css */
.chairman-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px;
    font-family: Arial, sans-serif;
    position: relative;
}

.header-section {
    text-align: center;
    margin-bottom: 48px;
}

.main-title {
    color: #1A45AC;
    font-size: 40px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    margin-bottom: 0%;
}

.title-underline {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 128px;
    height: 4px;
    background-color: #F6C300;
}

.subtitle {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 48px;
    font-weight: 500;
}

.chairman-content-wrapper {
    display: flex;
    gap: 32px;
    position: relative;
    align-items: center;
}

.text-content {
    flex: 2;
    line-height: 1.6;
    color: #444;
    font-size: 17.6px;
    text-align: justify;
}

.media-section {
    flex: 1;
    position: relative;
}

.image-container {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.chairman-image {
    width: 100%;
    height: auto;
    display: block;
}

.chairman-video {
    width: 100%;
    height: auto;
    display: block;
}







.icon {
    width: 24px;
    height: 24px;
}

.instagram {
    color: #E1306C;
}

.youtube {
    color: #FF0000;
}

.whatsapp {
    color: #25D366;
}

.twitter {
    color: #1DA1F2;
}

.facebook {
    color: #4267B2;
}



@media screen and (max-width: 768px) {

    .chairman-home-image {
        height: 52vh;
      }
    .chairman-content-wrapper {
        flex-direction: column;
        align-items: normal;
    }

    .main-title {
        font-size: 30px;
    }

    .subtitle {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .chairman-home-image {
        height: 32vh;
      }
    /* .chairman-home-container{
        height: 150px;
    } */
    .chairman-home-title{
        font-size: 1.5rem;
    }

    .chairman-container {
        padding: 25px;
    }

    .social-icon {
        width: 36px;
        height: 36px;
    }

    .icon {
        width: 20px;
        height: 20px;
    }
}

/* AboutChairman.css */
.about-chairman-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px;
    font-family: Arial, sans-serif;
}

.about-chairman-title {
    color: #1A45AC;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    position: relative;
    margin-bottom: 3rem;
}

.title-underline {
    width: 100px;
    height: 4px;
    background-color: #F6C300;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.chairman-content {
    display: flex;
    gap: 4rem;
    align-items: center;
}

.chairman-image-wrapper {
    flex: 0 0 45%;
}

.aboutimage-container {
    position: relative;
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 0 1px #e0115f;
}

.thumb-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.thumb-image:hover {
    transform: scale(1.05);
}

.chairman-image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 1;
}

.chairman-video {
    width: 100%;
    height: 30vh;
    display: block;
    position: relative;
    z-index: 1;
}

.chairman-info {
    flex: 1;
    padding-top: 1rem;
}

.chairman-name {
    color: #333;
    font-size: 2.8rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.chairman-designation {
    color: #666;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    font-weight: normal;
}

.chairman-description {
    color: #444;
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: justify;
}

@media (max-width: 768px) {
    .chairman-content {
        flex-direction: column;
        gap: 2rem;
    }
    

    .chairman-image-wrapper {
        flex: 0 0 100%;
    }
    
    .about-chairman-title {
        font-size: 2rem;
    }
    
    .chairman-name {
        font-size: 2.2rem;
    }
    
    .chairman-designation {
        font-size: 1.2rem;
    }
}

@media (prefers-color-scheme: dark) {
    .aboutimage-container {
        box-shadow: 0 0 0 1px #ff1493;
    }
}


@media (max-width: 480px) {
    
    .about-chairman-container {
        padding: 25px;
    }
}