/* AllBlogs.css */
.allblogs-heading {
  text-align: center;
}

.all-blogs-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
}

.blogs-list {
  width: 100%;
  margin: 0;
}


.blog-card {

  background-color: white;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card img {
  width: 40%;
  height: 200px;
  object-fit: contain;
}

.blog-card h2 {
  font-size: 1.5rem;
  margin: 10px;
  font-weight: 600;
}

.blog-card p {
  margin: 10px;
  color: #666;
}

.blog-card a {
  display: inline-block;
  margin: 10px;
  color: #007bff;
  font-weight: bold;
}

/* BlogCard.css */
.blog-card {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 16px;
  gap: 20px;
  width: 90%;
  max-width: 800px;
  background-color: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.blog-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.blog-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-title {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
}

.blog-category {
  font-size: 1rem;
  color: #666;
  margin: 8px 0;
}

.read-more {
  text-decoration: none;
  color: #0077cc;
  font-weight: bold;
  margin-top: 12px;
}

.read-more:hover {
  text-decoration: underline;
}


.allblogs-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  gap: 10px;
}

.allblogs-pagination button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.allblogs-pagination button:hover {
  background-color: #0056b3;
}

.allblogs-pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.allblogs-pagination span {
  font-size: 1.1rem;
  color: #333;
}

@media screen and (max-width: 768px) {
  .all-blogs-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .blog-card {
    box-sizing: border-box;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .all-blogs-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .blog-card {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .blog-card img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .blog-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .blog-card h2 {
    font-size: 0.9rem;
    margin: 5px;
  }

  .blog-category {
    font-size: 0.7rem;
  }

  .blog-card p {
    font-size: 0.7rem;
    margin: 5px;
    color: #666;
  }

  .blog-card a {
    font-size: 0.7rem;
    display: inline-block;
    margin: 0;
    color: #007bff;
    font-weight: bold;
  }
}


/* Container for the search bar */
.topblogs-search-input-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Search input styling */
.topblogs-search-input-container input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px;
  background: transparent;
}

/* Icon inside the input field */
.topblogs-search-icon {
  color: #777;
  font-size: 18px;
  cursor: pointer;
}

/* Focus effect */
.topblogs-search-input-container:focus-within {
  border-color: #0077cc;
  box-shadow: 0 4px 8px rgba(0, 119, 204, 0.2);
}

/* Responsive styling */
@media (max-width: 768px) {
  .topblogs-search-input-container {
    padding: 8px;
  }

  .topblogs-search-input-container input[type="text"] {
    font-size: 14px;
  }

  .topblogs-search-icon {
    font-size: 16px;
  }
}



/* blogsidebar container */
.blogsidebar {
  height: fit-content;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Search bar styling */
.blogsidebar input[type="text"] {
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.blogsidebar input[type="text"]:focus {
  border-color: #0077cc;
}

/* Header for top blogs */
.blogsidebar h3 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
}

/* Blog list */
.blogsidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.blogsidebar li {
  padding: 15px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease-in-out;
}

.blogsidebar li:hover {
  transform: translateY(-5px);
}

/* Blog links */
.blogsidebar a {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #0077cc;
}

.blogsidebar a:hover {
  text-decoration: underline;
}

/* Date text */
.blogsidebar p {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

/* Responsive design */
@media (max-width: 768px) {
  .blogsidebar {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  .blogsidebar h3 {
    font-size: 20px;
  }

  .blogsidebar a {
    font-size: 16px;
  }
}
