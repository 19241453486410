/* Cart.css */


.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .loading-cart-icon {
    font-size: 3rem;
    color: #007bff;
    animation: moveCart 1.5s ease-in-out infinite;
  }

  .loading-cart-text{
    font-size: 3rem;
    color: #007bff;
    animation: moveCart 1.5s ease-in-out infinite;
  }
  
  @keyframes moveCart {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(0);
    }
  }

  



.cart-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.back-button {
    background-color: #285ef1;
    padding: 10px;
    width: fit-content;
    text-decoration: none;

}

.back-button a {
    text-decoration: none;
    color: #ffffff;
}

.cart-container h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.cart-items {
    display: flex;
    flex-direction: column;
}

.cart-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid gainsboro;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 15px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cart-item-image {
    width: 100px;
    height: 150px;
    object-fit: cover;
    margin-right: 20px;
}

.cart-item-details {
    flex: 1;
    padding: 0 24px 12px;
}

.cart-item-details h3 {
    margin: 0px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #212121;
    line-height: 1;
    display: inline;
}

.item-subtopic {
    font-size: 0.9rem;
    color: #666;
    margin: 0px;
    margin-bottom: 10px;

}

.item-price {
    font-size: 18px;
    font-weight: 500;
    color: #212121;
    margin: 0px;
    margin-bottom: 10px;
}

.item-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.quantity-button {
    background-color: #ffffff;
    color: black;
    border: 1px solid rgb(255, 255, 255);
    padding: 5px;
    border-radius: 30%;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease;
}

.quantity-button:hover {
    background-color: #007bff77;
    cursor: pointer;
}

.icon{
    color: #007bff;
}

.quantity {
    font-size: 1.2rem;
    font-weight: bold;
}

.remove-button {
    font-family: Inter, -apple-system, Helvetica, Arial, sans-serif;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    justify-content: end;
    align-items: end;
    font-weight: bold;
    padding: 0;
}

.remove-button:hover {
    color: #285ef1;
}

.cart-summary {
    margin-top: 30px;
    padding: 20px;
    border-radius: 10px;
    text-align: right;
}

.cart-summary h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.proceed-button {
    background-color: #285ef1;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.2s ease;
}

.proceed-button:hover {
    background-color: #218838;
}

.empty-cart-message {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    margin-top: 50px;
}

/* Cart.css */

.address-form {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.address-form h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.addressform-container{
    margin: opx auto;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.place-order-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.required {
    color: red;
    margin-left: 5px;
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
    .cart-item {
        align-items: flex-start;
    }

    .back-button a {
        text-decoration: none;
        color: #ffffff;
        font-size: 0.7rem;
    }

    .cart-container h1 {
        text-align: center;
        font-size: 2.0rem;
        color: #333;
        margin-bottom: 20px;
    }

    .cart-item-image {
        width: 20%;
        height: auto;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .cart-item-details h3 {
        font-size: 16px;
    }

    .cart-summary {
        text-align: center;
    }

    .cart-item-details {
        flex: 1 1;
        padding: 0 10px 12px;
    }

    .item-subtopic {
        font-size: 0.6rem;
        margin: 0px;
        margin-bottom: 10px;

    }

    .item-price {
        font-size: 18px;
        margin: 0px;
        margin-bottom: 10px;
    }

    .cart-summary h3 {
        font-size: 1.3rem;
        color: #333;
        margin-bottom: 20px;
    }
}



.login-prompt {
    text-align: center;
    padding: 40px 20px;
  }
  
  .login-prompt h2 {
    margin-bottom: 16px;
    color: #333;
  }
  
  .login-prompt p {
    margin-bottom: 24px;
    color: #666;
  }
  
  .login-button {
    padding: 12px 24px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
