.auth-container {
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
}

.poster-image {
  width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
}

.poster-image img {
  width: 100%;
  height: 400px; /* Changed from 100% to auto to maintain aspect ratio */
  display: block; /* Added to remove any potential spacing issues */
  /* object-fit:fill;  */
  max-width: 100%; /* Ensures image doesn't overflow container width */
}

.auth-form-container {
  position: relative; /* Changed from absolute to relative */
  margin-top: -15vh; /* Negative margin to create overlap */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  padding: 20px;
  z-index: 2;
  margin-bottom: 40px; /* Added to ensure space at bottom */
}

/* Rest of the CSS remains exactly the same */
.auth-container .auth-form {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth-container .logo {
  display: block;
  width: 120px;
  height: auto;
  margin: 0 auto 20px;
  object-fit: contain;
}

.auth-container h2 {
  color: black;
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
}

.form-group {
  margin-bottom: 20px;
}

.auth-container input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.auth-container input:focus {
  outline: none;
  border-color: #FF6B6B;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #FF6B6B;
  color: black;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-container .submit-btn:hover {
  background-color: #ff5252;
}

.auth-container .success-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  15% { opacity: 1; }
  85% { opacity: 1; }
  100% { opacity: 0; }
}

.auth-container p {
  text-align: center;
  margin-top: 20px;
}

.auth-container a {
  color: #FF6B6B;
  text-decoration: none;
}

.auth-container a:hover {
  text-decoration: underline;
}

.forgot-password{
  text-align: center;
}

@media (max-width: 480px) {
  .poster-image img {
    height: 200px; 
  }
  .auth-form-container {
    margin-top: -11vh;
  }
}



/* Popup overlay */
.forgot-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup container */
.forgot-popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  position: relative; /* Required to position the close icon */
}

/* Close icon */
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #ff4e4e;
  cursor: pointer;
}

.forgot-popup-container input{
  width: 90%;
}

.forgot-popup-container button {
  width: 100%;
}
/* Style for input fields and buttons */
.forgot-popup-container input,
.forgot-popup-container button {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
}


@media (max-width:480px){
  .forgot-popup-container {
    width: 250px;
  }
}