input.error,
select.error {
  border: 1px solid red !important;
}

.Toastify__toast{
  z-index: 9999; /* Adjust this value as needed */
}

.Toastify__toast{
  z-index: 1;
}

.subscription-section {
    padding: 50px;
    background-image: url("./subscription-bg.png");
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 80vh;
    background-position: center;
    display: flex;
    flex-direction: column;
  }
  
  .subscription-heading h2 {
    text-align: center;
    margin: 0;
  }
  
  .hallticket-form-container {
    margin-top: 20px;
    background-color: white;
    border: 1px solid #0056b3;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px #1a45ac;
  }
  
  .subscription-hallticket-body {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .subscription-hallticket-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .subscription-hallticket-column label {
    display: flex;
    align-items: center; /* Align label and input in a row */
    justify-content: space-between;
    gap: 10px;
  }
  
  .required {
    color: red;
    margin-left: 5px;
    font-weight: bold;
  }
  
  
  
  .subscription-hallticket-column label .heading {
    display: inline-block;
    width: 190px; /* Set fixed width for labels */
    text-align: left; /* Keep text aligned to the left */
  }
  
  .subscription-hallticket-column input,
  .subscription-hallticket-column select {
    flex: 1; /* Input fields take up remaining space */
    padding: 8px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
  }

  .subscription-files-column{
    align-items: center;
    justify-content: center;
    text-align: center;
    /* border: 1px dashed #ccc; */
    padding: 20px;
    border-radius: 5px;
    margin: auto;
  }
  .subscription-photo-column {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .subscription-photo-column p{
    margin: 0;
  }
  
  .subscription-photo-column input {
    margin-top: 10px;
  }
  
  .subscription-hallticket-footer {
    text-align: center;
    margin-top: 20px;
  }
  
  .subscription-hallticket-footer button {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }
  
  .subscription-hallticket-footer button:hover {
    background-color: #0056b3;
  }
  
  .subscription-hidden-label {
    visibility: hidden; /* Hide the label */
    height: 2rem; /* Maintain space in layout */
  }
  .subscription-hidden-label span {
    display: block; /* Ensure it takes up space vertically */
  }
  
  .subscription-photo-container {
    width: 150px;
    height: 150px;
    border: 2px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  }
  
  .subscription-profile-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .subscription-placeholder-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  
  .subscription-profile-placeholder-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Style for the input type="file" button */
  .subscription-photo-column input[type="file"] {
    display: none; /* Hides the default file input */
  }
  
  .subscription-photo-column .subscription-custom-file-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Blue background */
    color: #fff; /* White text */
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
  }
  
  .subscription-photo-column .subscription-custom-file-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .subscription-photo-column .subscription-custom-file-button:active {
    background-color: #004085;
    transform: translateY(0);
  }
  
  
  @media (min-width: 1024px) {
    .subscription-hallticket-body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 20px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .hallticket-container {
      width: 90%;
    }
  
    .subscription-hallticket-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    .subscription-hallticket-column {
      flex: 1;
    }
  
    .subscription-photo-column {
      margin-top: 20px;
    }
  }
  
  @media (max-width: 767px) {
    .subscription-section {
      padding: 50px 10px;
    }
    .hallticket-container {
      width: 95%;
      padding: 15px;
      box-sizing: border-box;
    }
  
    .hallticket-header h2 {
      font-size: 12px;
      padding: 5px 0;
    }
  
    .subscription-hallticket-body {
      flex-direction: column;
      gap: 15px;
    }
  
    .subscription-hallticket-column label span {
      width: 140px;
      font-size: 13px;
    }
  
    .subscription-hallticket-column input,
    .subscription-hallticket-column select {
      font-size: 12px;
      padding: 6px;
    }
  
    .subscription-photo-column {
      padding: 10px;
    }
  
    .subscription-hallticket-footer button {
      width: 100%;
      font-size: 14px;
      padding: 8px;
    }
  }
  @media (max-width: 480px) {
    .subscription-hallticket-column label .heading {
      width: 100px;
    }
  }

  @media (max-width: 321px) {
    .subscription-files-column{
      padding: 0%;
    }
    .subscription-hallticket-column label .heading {
      width: 80px;
    }
  }
  
  .subscription-dropdown {
    padding: 30px;
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .subscription-dropdown select {
    padding: 10px;
    width: 50%;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  
  .submission-status {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .submission-status.loading {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .submission-status.success {
    background-color: #dff0d8;
    color: #3c763d;
  }
  
  .submission-status.error {
    background-color: #f2dede;
    color: #a94442;
  }
  
  
  .subscription-clear-photo-button {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .subscription-clear-photo-button:hover {
    background-color: #d32f2f;
  }

  .status-box-  overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 999;  /* Just below the status box */
  }
  
  .status-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .status-box-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    display: block;
    display: flex;  
    justify-content: center;
    align-items: center;
  }
  
  /* Loading state animation */
  .status-box-icon.fa-circle-notch {
    animation: spin 1s linear infinite;
    color: #3b82f6; /* blue */
  }
  
  /* Success state */
  .status-box-icon.fa-circle-check {
    color: #22c55e; /* green */
  }
  
  /* Error state */
  .status-box-icon.fa-circle-exclamation {
    color: #ef4444; /* red */
  }
  
  /* Message text styling */
  .status-box-message {
    color: #000000;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
  }


  
  
  /* Spin animation for loading icon */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  