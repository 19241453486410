/* NotificationCards.css */
.notifications-container {
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  gap:20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  /* position: relative; */
}

.notification-card {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out forwards;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.notification-card-content {
  padding: 24px;
  flex-wrap: wrap;
}

.notification-card:hover {
  transform: scale(1.05);
  /* Slightly enlarges the card */
  background: #f6c300;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 1);
}

.notification-card-content h2 {
  margin: 0 0 16px 0;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}

.notification-date-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666666;
}

.calendar-icon {
  width: 18px;
  height: 18px;
  stroke-width: 1.5;
}

.notification-card-footer {
  background: #1e3a8a;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-read-more {
  color: #ffffff;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}

.notification-read-more:hover {
  text-decoration: underline;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 16px;
}

.error {
  text-align: center;
  padding: 40px;
  color: #dc2626;
  font-size: 16px;
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .notifications-container {
    grid-template-columns: repeat(2,1fr);
    padding: 16px;
    gap: 16px;
    box-sizing: border-box;
  }

  .notification-card-content {
    padding: 26px;
  }

  .notification-card-footer {
    padding: 14px 20px;
  }

  .notification-card-content h2 {
    font-size: 18px;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 375px) {
  .notifications-container {
    padding: 12px;
    gap: 12px;
  }

  .notification-card-content {
    padding: 16px;
  }

  .notification-card-footer {
    padding: 12px 16px;
  }
}

/* Animation for cards */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notification-home-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
}

.notification-home-image {
  width: 100%;
  height: 55vh;
  object-fit: cover;
  filter: brightness(0.65);
}

.notification-home-text-container {
  position: absolute;
  right: 100px; /* Adjust as needed */
  top: 50%;
  text-align: right;
  transform: translateY(-50%);
  opacity: 0;
  animation: slideIn 1s ease-out forwards;
}

.notification-home-title {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-left: 10%;
    transition: transform 0.3s ease, color 0.3s ease;
}

.notification-home-title:hover {
  transform: scale(1.05);
  color: #f0f0f0;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translate(100%, -50%); /* Start from the right */
  }
  100% {
    opacity: 1;
    transform: translate(0, -50%); /* End at its position */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {

  .notification-home-image {
    height: 52vh;
  }
  
  .notification-home-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .notifications-container {
    grid-template-columns: 1fr;
  }
  .notification-home-title {
    font-size: 1.0rem;
    margin-left: 5%;
  }
  .notification-home-image{
    height: 32vh;
  }
}