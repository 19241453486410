
.gallery-entire{
    display: flex;
    align-items: center;
}

.gallery-left-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    border-radius: 0 50% 50% 0;
    border-right: 2px solid #99b6fa;
    padding: 30px 0;

}
.gallery-right-container{
    width: 100%;
    height: 100vh;
    background-color: #E7EEFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 500px;
    gap: 30px;
    border:2px solid #99b6fa;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.gallery-right-container img {
    width: 100%; /* Make images responsive */
    max-width: 400px; /* Set a maximum width */
    height: 300px; /* Maintain aspect ratio */
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth animation for hover effects */
    margin: 10px;
}

/* Hover effect for images */
.gallery-right-container img:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}


.gallery-inner{
    width: 80%;
    height: 50%;
    background-color: #f6c300;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gallery-inner .gallery-buttons{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


/* Style for category buttons */
.gallery-inner .gallery-buttons button {
    background-color: #fff;
    color: black;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.gallery-inner .gallery-buttons button.active {
    background-color: #1a45ac; /* Darker purple for active state */
    transform: scale(1.05); /* Slightly enlarge active button */
    color: #fff;
}

.gallery-inner .gallery-buttons button:hover {
    background-color: #1a45ac; /* Lighter purple on hover */
    color: #fff;
}

/* Style for pagination container */
.gallery-pagination {
    position: absolute;
    bottom: -270px; /* Adjust the bottom spacing as needed */
    right: 150px;  /* Adjust the right spacing as needed */
    display: flex;
    align-items: center;
    gap: 10px;
}


/* Style for pagination buttons */
.gallery-pagination button {
    background-color: #f6c300; /* Soft purple */
    color: #1a45ac;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.gallery-pagination button:hover {
    background-color: #1a45ac; /* Darker purple on hover */
    color: white;
}

.gallery-pagination button:disabled {
    background-color: #d3d3d3; /* Light gray for disabled buttons */
    cursor: not-allowed;
    color: grey;
}

.gallery-pagination #page-info {
    font-size: 16px;
    font-weight: bold;
    color: #483d8b; /* Dark purple for text */
}

.gallery-title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: bold;
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
}

.gallery-title:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 3px;
    background: #0047AB;
}

.gallery-title span:first-child {
    color: #0047AB;
}

.gallery-title span:last-child {
    color: #FFD700;
}

.gallery-subtitle-container{
    display: flex;
    justify-content: space-around;
}

.gallery-achievementsimage{
    width: 200px;
    height: 200px;
    margin-top: -100px;
}

.Gallery-achievements{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 100px;
  }

  .Gallery-achievements img {
    padding: 10px;
    background-color: #f6c300;
    width: 250px;
    height: 400px;
  }


@media (max-width:768px){
    .gallery-left-container{
        width: 50%;
        height: 100vh;
        padding: 20px 0;
    }

    .gallery-inner{
        width: 70%;
        height: 50%;
    }

    .gallery-right-container{
        padding-left: 420px;
        padding-top: 10px;
        padding-right: 0px;
    }
    .gallery-right-container img {
        max-width: 300px; /* Reduce image size for smaller screens */
        margin: 8px;
    }

    .gallery-pagination {
        bottom: -225px; /* Adjust the bottom spacing as needed */
    }
    .gallery-subtitle-container{
        margin: 25px;
    }
}

@media (max-width:480px){
    .gallery-entire{
        flex-direction: column;
        align-items: normal;
    }

    .gallery-left-container{
        width: 100%;
        height: 50vh;
        border-radius: 0 0 50% 50%;
        border-right: 0;
        border-bottom: 2px solid #99b6fa;
    
    }

    .gallery-right-container{
        height: 70vh;
        padding-left: 0px;
        padding-top: 300px;
    }

    .gallery-inner{
        width: 70%;
        height: 80%;
    }

    .gallery-right-container img {
        max-width: 90%; /* Use full width of the container */
        margin: 5px;
        margin-left: 20px;
    }

    .gallery-inner .gallery-buttons button {
        padding: 8px 10px;
        font-size: 10px;
    }

    .gallery-pagination {
        bottom: -300px; /* Adjust the bottom spacing as needed */
    }

    .gallery-subtitle-container{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .gallery-achievementsimage{
        width: 200px;
        height: 200px;
        margin-top: -10px;
    }
}





@media (min-width: 1024px) and (max-width: 1439px) {
    .gallery-right-container img {
        height: 250px; 
    }

    .gallery-pagination {
        bottom: -330px;
    }
}














