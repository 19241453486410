/* Container for the image */
.informationcenter-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
}

.informationcenter-image {
    width: 100%;
    height: 55vh;
    object-fit: fill; 
    filter: brightness(0.65);
}


/* Filter Icon */
.filter-icon {
    width: 18px;
    height: 18px;
    margin-left: 20px;
}

/* Filter Button */
.filter-button {
    height: 50px;
    width: 150px;
    background-color: #1A45AC;
    border: none;
    border-radius: 8px;
    color: white;
    display: flex;
    box-sizing: border-box;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

/* Filter Text */
.filter-text {
    font-size: 15px;
}

/* Filters Section */
.filters-section {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center;
    position: relative;
    top: 20px;
    align-items: center;
    gap: 20px; /* Add spacing between items */
}

/* .filters-section > * {
    margin-left: 20px;
} */

/* Dropdown Container */
.custom-information-dropdown {
    position: relative;
    width: 200px; /* Default width for desktop */
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.information-dropdown {
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding: 10px 30px 10px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* Dropdown Arrow */
.dropdown-arrow {
    position: absolute;
    right: 20px;
    pointer-events: none;
    fill: #888;
    width: 16px;
    height: 16px;
}

/* Table Container */
.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin: 0px 0px 50px 0px;
}

/* Table Styling */
.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    table-layout: fixed;
    overflow-x: scroll;
}

.table th,
.table td {
    padding: 12px 15px;
    text-align: center;
    width: 33%;
}

.table th {
    background-color: #F6C300;
    font-weight: bold;
    line-height: 3.5;
    font-size: 22px;
}

.table td {
    padding: 12px 15px;
    border-color: #1A45AC;
    border-top: 1px solid #1A45AC;
    border-bottom: 1px solid #1A45AC;
    line-height: 3.5;
}

/* View More Button */
.view-more-button {
    background-color: #1A45AC;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    position: relative;
    top: 20px;
    align-self: flex-end;
    margin-right: 150px;
    margin-bottom: 50px;
}

.view-more-button:hover {
    background-color: #1A45AC;
}

/* Media Queries */

@media (max-width: 425px) {
     .table {
        width: auto;
        overflow-x: scroll;
    }

}


/* For small screens, like mobile phones (max-width: 600px) */
@media (max-width: 768px) {
    .informationcenter-image {
        height: 52vh;
    }

    /* Filters Section: Stack the dropdowns vertically */
    .filters-section {
        flex-direction: column;
        gap: 15px; /* Reduce gap for mobile screens */
    }

    /* Custom Dropdown: Set width to full on mobile */
    .custom-information-dropdown {
        width: 100%; /* Make dropdowns take full width on mobile */
        margin-bottom: 10px; /* Add spacing between dropdowns */
    }

    .information-dropdown {
        width: 100%; /* Ensure dropdown fills the screen width */
        height: 50px; /* Adjust height for smaller screens */
        padding: 10px; /* Adjust padding */
    }

    .filter-button {
        width: 50%; /* Make the button full width on mobile */
        margin-top: 20px; /* Add spacing between button and dropdown */
    }

    .view-more-button {
        width: 100%; /* Make the view more button full width */
        /* margin-right: 0; */
        margin-left: 0;
        margin-top: 20px;
    }

   
    .table th {
        line-height: 2.5;
        font-size: 15px;
    }

    .table td {
        padding: 12px 15px;
        line-height: 2.5;
        font-size: 10px;
    }
}

/* For medium-sized screens, like tablets (max-width: 1024px) */
@media (max-width: 1024px) {
    /* Filters Section: Adjust for tablet */
    .filters-section {
        gap: 20px; /* Increase gap between items */
    }

    .custom-information-dropdown {
        width: 250px; /* Make dropdowns wider for tablets */
    }

    .information-dropdown {
        width: 100%;
        padding: 10px 30px 10px 10px;
    }

    .view-more-button {
        width: 200px; /* Adjust view more button width */
        margin-right: 65px;
    }
}

/* For large screens (above 1024px) */
@media (min-width: 1025px) {
    .filters-section {
        gap: 30px;
    }

    .custom-information-dropdown {
        width: 250px; /* Larger dropdowns */
    }

    .information-dropdown {
        width: 100%;
        padding: 10px 30px 10px 10px;
    }
}


@media (max-width:480px) {
    .informationcenter-image {
        height: 32vh;
    }
    
}


/* Search Section */
.information-search-section {
    display: flex;
    justify-content: center; /* Center the search bar */
    align-items: center;
    margin: 20px 0; /* Add vertical spacing */
    width: 100%; /* Full width for responsiveness */
}

/* Search Bar Styling */
.information-search-bar {
    width: 50%; /* Default width for larger screens */
    max-width: 600px; /* Limit maximum width */
    height: 25px; /* Consistent height */
    padding: 10px 15px; /* Inner spacing for text */
    font-size: 16px; /* Readable font size */
    border: 2px solid #1A45AC; /* Match the design color scheme */
    border-radius: 5px; /* Rounded edges */
    outline: none; /* Remove default focus outline */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

/* Search Bar Focus Effect */
.information-search-bar:focus {
    border-color: #F6C300; /* Highlight border on focus */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Emphasize focus */
}

/* Media Queries for Search Section */

/* Small screens (max-width: 768px) */
@media (max-width: 768px) {
    .information-search-bar {
        width: 80%; /* Adjust width for smaller screens */
        font-size: 14px; /* Slightly smaller text */
    }
}

/* Very small screens (max-width: 425px) */
@media (max-width: 425px) {
    .information-search-bar {
        font-size: 14px; /* Maintain readability */
    }
}
