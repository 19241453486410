/* App.css */
.app-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.hero-section {
  width: 100%;
  height: 55vh;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-title {
  text-align: center;
  margin: 40px 0;
}

.blue-text {
  color: #0066cc;
  font-weight: bold;
}

.yellow-text {
  color: #ffd700;
  font-weight: bold;
  margin-left: 10px;
}

.blue-line {
  height: 3px;
  background-color: #0066cc;
  width: 100px;
  margin: 10px auto;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}

.nav-button {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-buttons span{
  font-size: 12px;
  font-weight: 500;
}

.nav-button img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.nav-button:hover,
.nav-button.active {
  background: #0066cc;
  color: white;
}

.search-bar {
  position: relative;
  max-width: 600px;
  margin: 30px auto;
  text-align: center;
}

.search-bar input {
  width: 80%;
  padding: 15px 40px 15px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.course-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 20px;
}

.course-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.course-card:hover {
  transform: translateY(-5px);
}

.course-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.course-card h3 {
  padding: 15px;
  margin: 0;
}

.course-card p {
  padding: 0 15px;
  margin: 5px 0;
}

.join-button {
  background: #0066cc;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.faq-container {
  max-width: 800px;
  margin: 30px auto;
  padding: 0 20px;
}

.faq-item {
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.faq-question {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  /* background: white; */
  transition: all 0.3s ease;
}

.faq-question:hover,
.faq-item.active {
  background: #0066cc;
  color: white;
}

.faq-number {
  margin-right: 15px;
  font-weight: bold;
}

.toggle-icon {
  margin-left: auto;
  font-size: 20px;
}

.faq-answer {
  padding: 15px;
  background: #ffd700;
  color: #0066cc;
}

.course-contact-section {
  padding: 40px 20px;
}

.course-contact-section h2 {
  text-align: center;
  margin-bottom: 30px;
}

.course-contact-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  align-items: center;
}

.course-contact-image {
  width: 50%;
  object-fit: cover;
}

.course-contact-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.course-contact-form input,
.course-contact-form select,
.course-contact-form textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.course-contact-form textarea {
  height: 120px;
  resize: vertical;
}

.submit-button {
  background: #ffd700;
  color: black;
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

/* Responsive Design */
@media (max-width: 768px) {
  /* .nav-buttons {
    flex-direction: column;
  } */

  .course-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .course-contact-container {
    flex-direction: column;
  }

  .course-contact-image,
  .course-contact-form {
    width: 100%;
  }

  .hero-image {
    height: 52vh;
    object-fit: fill;
  }

}

@media (max-width: 480px) {
  .course-cards {
    grid-template-columns: 1fr;
  }

  .nav-buttons {
    flex-direction: column;
    /* align-items: center; */
    margin: auto 40px;
  }

  .hero-section {
    height: 32vh;
  }

  .section-title {
    font-size: 24px;
  }

  .nav-button {
    padding: 10px 20px;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}



.courses-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}

.courses-popup.visible {
  display: flex;
}

.courses-popup-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

/* For the close button (cross icon) */
.courses-popup-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.courses-popup-close-icon:hover {
  color: #ff0000;
}

/* For the "For more details, contact us" button */
.contact-us-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.contact-us-button:hover {
  background-color: #0056b3;
}
