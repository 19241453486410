/* Button container */
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Add some space between login/signup button and profile button */
}

/* Login/Signup button */
.login-signup-button {
  padding: 8px 16px; /* Adjust padding to make the button more compact */
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px; /* Adjust font size to match the navbar style */
  border-radius: 4px; /* Add a subtle border radius for consistency */
  font-family: 'Poppins', sans-serif;
}


/* Profile Dropdown Container */
.profile-dropdown-container {
  position: relative;
  display: inline-block;
}

/* Profile Button */
.profile-dropdown-button {
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  width: 2rem; /* Fixed size */
    height: 2rem; /* Make sure width and height are equal */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-dropdown-button:hover {
  background-color: #0056b3;
}

/* Dropdown Menu */
.profile-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0;
  min-width: 150px;
  z-index: 1000;
  display: none; /* Prevents space allocation */
  flex-direction: column;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
}

.profile-dropdown-menu.show {
  display: flex; /* Visible when active */
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}

/* Dropdown Menu List Items */
.profile-dropdown-menu li {
  padding: 0;
}

.profile-dropdown-menu button {
  background: white;
  border: none;
  color: #333;
  padding: 0.5rem 1rem;
  text-align: left;
  width: 100%;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

.profile-dropdown-menu button:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

.profile-dropdown-menu button.logout:hover {
  background-color: #fd7171;
  color: #ffffff;
}

.profile-dropdown-menu button:focus {
  outline: none;
}

/* Tablet and Mobile View Adjustments */
@media screen and (max-width: 768px) {
  .login-container {
    justify-content: start;
  
  }
  .profile-dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-dropdown-button {
    font-size: 1rem;
    padding: 0.6rem 1rem;
    text-align: left;
    background-color: #007bff;
    display: flex;
    justify-content:center ;
    
  }

  .profile-dropdown-menu {
    position: static;
    box-shadow: none;
    border-radius: 0;
    margin-top: 0.5rem;
    width: 100%;
    padding: 0;
    display: none; /* Hidden by default */
  }

  .profile-dropdown-menu.show {
    display: block; /* Change to block for mobile view */
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  .profile-dropdown-menu li {
    border-bottom: 1px solid #ddd;
  }

  .profile-dropdown-menu li:last-child {
    border-bottom: none;
  }

  .profile-dropdown-menu button {
    font-size: 1rem;
    padding: 0.75rem 1rem;
    text-align: left;
  }

  .profile-dropdown-menu button:hover {
    background-color: #f0f0f0;
    color: #007bff;
  }
}
