/* General Styles for the Reset Password Page */
.reset-password-container {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 500px;
    margin: 50px auto;
  }
  
  .reset-password-title {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .reset-password-form {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #6c8fbd;
    box-shadow: 0 0 4px rgba(108, 143, 189, 0.6);
  }
  
  .submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
  
  .submit-btn:focus {
    outline: none;
  }
  
  /* Message Styles */
  .message {
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
  }
  
  .message.error {
    color: #f44336;
  }
  
  .message.success {
    color: #4CAF50;
  }

  /* Popup Overlay */
.reset-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  /* Popup Container */
  .reset-popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
  }
  
  .reset-popup-container p {
    margin-bottom: 20px;
  }
  
  .reset-popup-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reset-popup-btn:hover {
    background-color: #0056b3;
  }
  
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .reset-password-container {
      padding: 15px;
    }
  
    .reset-password-title {
      font-size: 20px;
    }
  
    .form-input {
      font-size: 16px;
    }
  
    .submit-btn {
      font-size: 18px;
    }
  }
  