/* Styles for ChatBanner component */
.mission-vision-home-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: grey;
    color: white;
    /* padding: 2.5rem 1.5rem; */
    /* width: 100%; */
    /* height: 150px; */
}
  
.mission-vision-home-image {
    width: 100%;
    height: 55vh;
    object-fit: fill;
    filter: brightness(0.65);
  }

  .mission-vision-home-text-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    animation: slideIn 1s ease-out forwards;
  }

.mission-vision-home-content {
    max-width: 800px;
}
  
.mission-vision-home-title {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-left: 10%;
    transition: transform 0.3s ease, color 0.3s ease;
  }

  
.mission-vision-home-title:hover {
    transform: scale(1.05);
    color: #f0f0f0;
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translate(-100%, -50%);
    }
    100% {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }

  /* Styles for MissionVision component */
/* .mission1-vision-container {
  padding: 40px;
  background-color: #ffffff;
  margin-top: 0px;
}

.content1-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.mission1, .vision1 {
  display: flex;
  flex-direction: column;
}

.section-header1 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.vertical-line1 {
  width: 4px;
  height: 30px;
  background-color: #F6C300;
  margin-right: 10px;
}

.missionvision1 {
  font-size: clamp(24px, 4vw, 32px);
  font-weight: bold;
  margin: 0;
  color: #1A45AC;
}

.mission-content1, .vision-content1 {
  display: flex;
  gap: 20px;
}

.para1 {
  flex: 1;
  line-height: 1.7;
  font-size: clamp(16px, 2vw, 20px);
  margin: 0;
}

.mission-image1, .vision-image1 {
  width: 100%;
  max-width: 400px;
  height: auto;
  aspect-ratio: 16 / 10;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 601px) and (max-width: 1024px) {
  .mission1-vision-container {
      padding: 30px;
  }

  .content1-wrapper {
      gap: 30px;
  }

  .mission-content1, .vision-content1 {
      gap: 15px;
  }

  .mission-image1, .vision-image1 {
      max-width: 300px;
      min-width: 250px;
  }

  .para1 {
      font-size: 16px;
  }
}

@media (max-width: 768px) {
  .container {
      flex-direction: column;
  }
}

@media (max-width: 600px) {
  .mission1-vision-container {
      padding: 20px;
  }

  .content1-wrapper {
      gap: 40px;
  }

  .mission-content1, .vision-content1 {
      flex-direction: column;
      gap: 20px;
  }

  .mission-image1, .vision-image1 {
      max-width: 100%;
      margin: 0;
  }

  .mission1 .mission-image1 {
      order: -1;
  }

  .vision-content1 {
      flex-direction: column;
  }

  .vision-text1 {
      order: -1;
  }
}

@media (max-width: 480px) {
  .mission-vision-home-container{
      height: 150px;
  }
  .mission-vision-home-title{
      font-size: 1.5rem;
  }
  .mission1-vision-container {
      padding: 15px;
  }

  .content1-wrapper {
      gap: 30px;
  }

  .section-header1 {
      margin-bottom: 15px;
  }

  .vertical-line1 {
      height: 24px;
  }
} */



.mission-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
}

.mission-section {
  display: flex;
  gap: 2rem;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}

.mission-section-image-container {
  /* flex: 1; */
  display: flex;
  justify-content: center;
}

.mission-section-image {
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
}


.mission-vertical-line{
  width: 4px;
  height: 30px;
  background-color: #F6C300;
  margin-right: 10px;
}

.mission-section-text {
  flex: 2;
  margin-bottom: 20px;
}

/* .mission-heading {
  font-size: 1.8rem;
  margin-bottom: 1rem;
} */

.mission-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mission-highlight {
  font-size: clamp(24px, 4vw, 32px);
  font-weight: bold;
  margin: 0;
  color: #1a4db5; /* Blue color */
}

.mission .mission-highlight {
  color: #f1a409; /* Yellow color for Mission */
}

.vision .mission-highlight {
  color: #f1a409; /* Yellow color for Vision */
}

.mission-para {
  line-height: 1.7;
  font-size: clamp(16px, 2vw, 20px);
  margin: 0;
}

/* Responsive Design */
@media (min-width: 768px) {
  .mission-div {
      flex-direction: row;
  }
  /* .vision {
      flex-direction: row-reverse;
  } */
}

@media (max-width: 767px) {
  .mission-div {
      flex-direction: column;
      text-align: start;
  }

  .vision-div {
      flex-direction: column-reverse;
      text-align: start;
  }
}

@media (max-width:780px){
  .mission-vision-home-image {
    height: 52vh;
  }
}

@media (max-width:480px) {
  .mission-vision-home-image {
    height: 32vh;
  }
  .mission-vision-home-title{
    font-size: 1.8rem;
  }
}
