.privacy-policy-container {
   max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy-container h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .privacy-policy-container p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 10px 0;
  }
  
  .privacy-policy-container ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .privacy-policy-container li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-policy-container h1 {
      font-size: 1.8rem;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.4rem;
    }
  
    .privacy-policy-container p {
      font-size: 0.95rem;
    }
  }
  